
import { useStore } from "vuex";
import { ref } from "vue";
import useQuasar from 'quasar/src/composables/use-quasar.js';;
let swReg;
if ("serviceWorker" in navigator) {
  window.addEventListener('load',()=>{    
    navigator.serviceWorker
    .register("./sw.js")
    .then((reg) => {
      swReg= reg; 
      swReg.pushManager.getSubscription().then()     
    })
    .catch((err) => console.warn("Error", err));
  })

  }
const useNotification = () => {

  const pushNotification = ref(false);
  const notificationActivated = ref(false);
  const store = useStore();
  const $q = useQuasar();
  

  const sendNotification = () => {
    const nofiticationOpt = {
      body: 'This is the notification body',
      icon: 'https://tecnicos.delmondexpress.com/img/icons/icon-384x384.png'
    }

    new Notification('Hola Mundo', nofiticationOpt)

  }

  const notify = () => {
    if (!window.Notification) {
      console.log('este navegador no soporta notificaciones');
      return;
    }    
    if (Notification.permission === 'granted') {
      pushNotification.value=true;
      sendNotification()
      

    } else if (Notification.permission === 'default' || Notification.permission !== 'denied') {
      Notification.requestPermission((permission) => {
        if (permission === 'granted') {
          pushNotification.value=true;
          sendNotification()
          
        }
      })
    }else{
      pushNotification.value=false;
    }
  }

  const verifySubscribe = ()=>{
    if (!window.Notification) {
      console.log('este navegador no soporta notificaciones');
      return;
    }
    pushNotification.value= false
    notificationActivated.value=false;
    if (Notification.permission === 'granted'){
      pushNotification.value = true;
      notificationActivated.value=true;   
      unsubscribe();
      generateSubscribe();
    }  

  }

  const getPublicKey = async () => {
    
    const resp = await store.dispatch('home/getPublicKeyPush')
        
    return resp;
}

const setSubscribe = async(subs)=>{
  const resp =  await store.dispatch('home/setSubscribe', subs)
  return resp;
}

const generateSubscribe = async()=>{
  
  if(!swReg) return console.log('There is not register');

  await getPublicKey().then((key)=>{
    swReg.pushManager.subscribe({
      userVisibleOnly : true,
      applicationServerKey: key
    }).then(res=>res.toJSON())
    .then(async (subs) =>{
      const resp = await setSubscribe(subs);      
    })    
  });
}

const unsubscribe = async ()=>{
  navigator.serviceWorker.ready.then((reg) => {
    reg.pushManager.getSubscription().then((subscription) => {
      subscription.unsubscribe().then((successful) => {
        }).catch((e) => {
        console.error(e);
      })
    })
  });
}


  return {     
    pushNotification,    
    generateSubscribe,
    unsubscribe, 
    verifySubscribe,
    notificationActivated  
  }


}


export default useNotification;
